
import hljs from 'highlight.js';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const codeBlock = ref<HTMLElement>();

    const code = computed(() => props.content.join('\r\n'));

    onMounted(() => {
      if (codeBlock.value) {
        hljs.highlightElement(codeBlock.value);
      }
    });

    return {
      code,
      codeBlock,
    };
  },
});
