
import { defineComponent } from 'vue';

import ContentCopyIcon from '@/icons/ContentCopy.vue';

export default defineComponent({
  components: {
    ContentCopyIcon,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    function toClipboard() {
      const copyField = document.createElement('textarea');
      document.querySelector('body')?.appendChild(copyField);
      copyField.value = props.content;
      copyField.select();
      document.execCommand('copy');
      copyField.remove();
    }

    return {
      toClipboard,
    };
  },
});
