
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    params: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    function getOptionalTranslation(isOptional: boolean) {
      return isOptional ? t('global.yes') : t('global.no');
    }

    return {
      getOptionalTranslation,
    };
  },
});
