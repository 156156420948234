
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import MethodExample from '@/components/Method/MethodExample.vue';
import MethodParams from '@/components/Method/MethodParams.vue';
import MethodUsage from '@/components/Method/MethodUsage.vue';
import Spinner from '@/components/Spinner/Spinner.vue';

import { IApiMethod } from '@/interfaces/iApiMethod';

import { getApiMethod } from '@/api';

export default defineComponent({
  components: {
    MethodExample,
    MethodParams,
    MethodUsage,
    Spinner,
  },
  setup() {
    const route = useRoute();

    const method = ref<IApiMethod>();

    const isLoaded = computed(() => !!method.value);

    async function fetchData() {
      const { params } = route;
      method.value = undefined;
      method.value = await getApiMethod(params.category as string, params.namespace as string, params.method as string);
    }

    watch(() => route.params, fetchData, { immediate: true });

    return {
      isLoaded,
      method,
    };
  },
});
